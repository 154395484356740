import React, { useState } from "react";
import { Box, CardMedia, Typography } from "@mui/material";
import { Info, Cancel } from "@mui/icons-material";
// import charger_img from "../assets/images/image-2-1.jpg";
import { DashStyles } from "../assets/styles/DashStyles.js";

const ChargerHeader = ({ charger }) => {
  const styles = DashStyles();
  const [isFlipped, setIsFlipped] = useState(false);
  const handleChargerInfo = () => {
    setIsFlipped(!isFlipped);
  };
  return (
    <>
      {
        // the_station.length !== 0 && the_station.name && (
        <Typography variant="h2">
          STATION
          {/* {the_station.name.toUpperCase()} */}
        </Typography>
        // )
      }
      <Box className={styles.chargerContainer}>
        {charger.length !== 0 && charger.name && (
          <>
            <Typography variant="h3">
              {charger.name.charAt(0).toUpperCase() + charger.name.slice(1)}
            </Typography>
            <Box
              className={`${styles.chargerBox} ${isFlipped ? styles.flip : ""}`}
            >
              {/* Front */}
              <Box
                className={`${styles.charger} ${styles.front}`}
                style={{ display: isFlipped ? "none" : "block" }}
              >
                <CardMedia
                  component="img"
                  src={`.${charger.imageUrl}`}
                  alt="charger"
                  className={styles.chargerImg}
                />
              </Box>
              {/* Back */}
              <Box
                className={`${styles.charger} ${styles.back}`}
                style={{ display: isFlipped ? "block" : "none" }}
              >
                <Typography variant="h3" style={{ marginTop: "9px" }}>
                  SPECS
                </Typography>
                <hr className={styles.hr} />
                <ul className={styles.chargerList}>
                  <li className={styles.chargerListItem}>
                    {charger.specs.Phase} PHASE
                  </li>
                  <li className={styles.chargerListItem}>
                    {charger.specs.Current} A
                  </li>
                  <li className={styles.chargerListItem}>{charger.specs.AC}</li>
                  <li className={styles.chargerListItem}>
                    TYPE {charger.specs.Type}
                  </li>
                </ul>
              </Box>
              {isFlipped ? (
                <Cancel
                  className={styles.chargerIcon}
                  onClick={handleChargerInfo}
                />
              ) : (
                <Info
                  className={styles.chargerIcon}
                  onClick={handleChargerInfo}
                />
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ChargerHeader;
