import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Typography } from "@mui/material";

import { FooterStyles } from "../assets/styles/FooterStyles";

function Footer() {
  const styles = FooterStyles();
  return (
    <AppBar position="static" className={styles.footer}>
      <Toolbar className={`${styles.toolbar} ${styles.center}`}>
        <Typography variant="h4" color="common.white">
          {" "}
          Powered by MeshPower Ltd
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
