import React, { useState } from "react";
import { Box, FormControl, Container, Input, Typography } from "@mui/material";
import { PageStyles } from "../assets/styles/page.js";
import ChargerHeader from "./ChargerHeader.js";
import OccupiedAuthBox from "./OccupiedAuthBox.js";
import PopUpError from "./PopUpError.js"

const Occupied = ({ api_host, rwandan_nums, charger }) => {
  const pageStyles = PageStyles();
  const [authPop, setAuthPop] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");

  const handleFormSubmit = async () => {
    if (phoneNumber === "") {
      setError("Please enter your phone number.");
    } else if (
      !rwandan_nums.some((prefix) => phoneNumber.startsWith(prefix)) ||
      !/^\d+$/.test(phoneNumber) ||
      phoneNumber.length < 10
    ) {
      setError("Please enter a correct phone number");
    } else {
      try {
        const res = await fetch(
          `${api_host}/Sessions/Phone/${phoneNumber}`,
        );
        const data = await res.json();
        if (data.status === "generated") {
          setAuthPop(true)
        } else {
          setError(`no active session was found for ${phoneNumber}`)
        }
      } catch (error) {
        console.log("Error fetching charger data, ", error);
      }
    }
  };

  const handleAuthPop = () => {
    setError(null);
    setAuthPop(!authPop);
  };

  return (
    <Container className={pageStyles.the_page}>
      <ChargerHeader charger={charger} />
      {error && (
        <PopUpError error={error} setError={setError} />
      )}
      {authPop && (
        <OccupiedAuthBox
          api_host={api_host}
          handleAuthPop={handleAuthPop}
          setError={setError}
          phoneNumber={phoneNumber}
        />
      )}
      <Box className={pageStyles.formContainer}>
        <FormControl className={pageStyles.form}>
          <br /> <Typography variant="h3">CHARGER OCCUPIED</Typography> <br />
          <Box>
            <Typography variant={error ? "error" : "h3"}>
              Enter Phone to Check Status
            </Typography>
          </Box>
          <br />
          <Input
            placeholder="Phone number"
            type="text"
            className={pageStyles.formInput2}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <br />
          <Container sx={{ marginTop: "8px" }}>
            <button
              className={pageStyles.formButton}
              type="submit"
              onClick={handleFormSubmit}
            >
              Check
            </button>
          </Container>
        </FormControl>
      </Box>
    </Container>
  );
};

export default Occupied;
