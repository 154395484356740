import { makeStyles } from "@mui/styles";

export const PageStyles = makeStyles((theme) => ({
  the_page: { padding: "10px", position: "relative" },
  status: { padding: "10px" },
  popBox: {
    backgroundColor: theme.palette.whitecolors.dark,
    position: "absolute",
    height: "150px",
    width: "50%",
    zIndex: "9999",
    top: "30%",
    left: "50%",
    transform: "translate(-50%,-30%)",
    padding: "10px",
    alignItems: "center",
    borderRadius: "4px",
  },
  popBoxInside: {
    backgroundColor: theme.palette.whitecolors.main,
    height: "100%",
    padding: "0",
    paddingTop: "20px",
  },
  popBoxH3: { width: "100%" },
  popBoxInput: {
    padding: "10px 3px !important",
    height: "15px",
    width: "95%",
    marginLeft: "2.5%",
    backgroundColor: "transparent",
    borderBottom: `0.5px solid transparent`,
    borderRadius: "4px",
    fontSize: "12px !important",
    marginTop: "-3px",
  },
  submitPopBox: {
    width: "50%",
    textAlign: "right",
    textDecoration: "underline",
    "&:hover": { cursor: "pointer", color: "navy" },
  },
  hr: { width: "80%" },
  popBoxfooter: {
    width: "100%",
    fontWeight: "bold",
    marginTop: "2px",
    color: "red",
  },
  popBoxfooter1: {
    float: "left",
    width: "40%",
    "&:hover": { cursor: "pointer", color: "navy" },
  },
  popBoxfooter3: {
    "&:hover": { cursor: "pointer", color: "navy" },
  },
  popBoxfooter2: {
    float: "right",
    width: "40%",
    marginRight: "20%",
    "&:hover": { cursor: "pointer", color: theme.palette.primary.red },
  },
  cancel: {
    cursor: "pointer",
    float: "right",
    marginTop: "-15px",
    marginRight: "-15px",
    "&:hover": { cursor: "pointer", color: theme.palette.primary.red },
  },
  formContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: "20px",
    zIndex: "1000",
  },
  form: {
    textAlign: "center",
    alignItems: "center",
  },
  formInput: {
    padding: "15px 3px !important",
    height: "15px",
    width: "160px",
    backgroundColor: theme.palette.whitecolors.input_light,
    color: "black",
    border: `1px solid ${theme.palette.whitecolors.input_dark}`,
    textAlign: "center",
    borderRadius: "4px",
    fontSize: "10px !important",
    marginTop: "6px",
  },
  formInput2: {
    padding: "15px 3px !important",
    height: "15px",
    width: "160px",
    backgroundColor: theme.palette.whitecolors.input_light,
    color: "black",
    border: `1px solid ${theme.palette.whitecolors.input_dark}`,
    textAlign: "center",
    borderRadius: "4px",
    fontSize: "10px !important",
    marginTop: "-6px",
  },
  formButton: {
    backgroundColor: theme.palette.primary.dark,
    marginTop: "-5px",
    color: theme.palette.whitecolors.main,
    padding: "7px 10px",
    border: "none",
    width: "70px",
    borderRadius: "3px",
    "&:hover": {
      color: "white",
      borderRadius: `0.5px solid ${theme.palette.primary.border_dark}`,
      backgroundColor: theme.palette.primary.light,
      cursor: "pointer",
    },
  },
  ul: {
    listStyleType: "none",
    padding: "0px",
    textAlign: "center",
    alignItems: "center",
    textSizeAdjust: "auto",
    fontFamily: theme.typography.fontFamily,
  },
  li: {
    marginTop: "7px",
    height: "30px",
    paddingBottom: "7px",
    padding: "0px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  table: {
    margin: "auto",
    width: "98%",
    textSizeAdjust: "auto",
    tableLayout: "fixed",
    borderCollapse: "collapse",
    fontFamily: theme.typography.fontFamily,
    '& td': {
      border: "3px solid #ddd",
    },
  },

  licomfirm: {
    marginTop: "-7px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: "0px",
  },
}));
