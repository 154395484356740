import { createTheme } from "@mui/material";

export const primary_main = "#43ad00";
export const primary_light = "#7ae045";
export const primary_dark = "#327A04";
export const secondary_main = "#fab000";
export const white = "#FFFFFF";
export const black = "#000000";
export const red = "#ff3333";
export const green = "#59d932";
export const orange = "#d97d32";

const theme = createTheme({
  palette: {
    primary: {
      main: primary_main,
      light: primary_light,
      dark: primary_dark,
      border_dark: "#1c4603",
      border_light: "#43ab01",
      red: red,
    },
    secondary: {
      main: secondary_main,
      light: "#ffe24b",
      dark: "#c28100",
    },
    whitecolors: {
      main: white,
      light: "#ffe24b",
      dark: "#eeeeee",
      input_light: "#D9D9D9",
      input_dark: "#c5c5c5",
    },
    blackcolors: {
      main: black,
      light: "#080808d0",
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif", // Set your default font here
    h2: {
      fontSize: "15px",
      color: primary_main,
      fontWeight: "bold",
      marginTop: "5px",
      marginBottom: "3px",
    },
    h3: {
      fontSize: "14px",
      color: black,
      fontWeight: "bold",
      marginTop: "2px",
    },
    h4: {
      fontSize: "13px",
      color: black,
      fontWeight: "bold",
      marginTop: "2px",
    },
    h5: {
      fontSize: "16px",
      fontWeight: "bold",
      marginTop: "5px",
    },
    error: {
      fontFamily: "Arial, sans-serif",
      fontSize: "14px",
      color: red,
      fontWeight: "light",
    },
  },
});

export default theme;
