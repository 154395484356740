import { makeStyles } from "@mui/styles";

export const AppStyles = makeStyles((theme) => ({
  app: {
    textAlign: "center",
    alignItems: "center",
    fontSize: "12px",
    maxWidth: theme.breakpoints.down('md'),
    height: "auto",
    // minHeight: "560px",
    backgroundColor: theme.palette.whitecolors.dark,
  },
  container: {
    minHeight: "83vh",
    backgroundColor: theme.palette.whitecolors.main,
    margin: "12px",
    borderRadius: "10px",
  },
}));
