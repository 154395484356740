import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PageStyles } from "../assets/styles/page.js";
import OTPInput from "./otp.js"

const OccupiedAuthBox = ({ api_host, handleAuthPop, setError, phoneNumber }) => {
  const navigate = useNavigate();
  const pageStyles = PageStyles();
  const [tryagain, setTryAgain] = useState(false)
  const handleAuth = async (inOtp) => {
    try {
      const resp = await fetch(`${api_host}/otp`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "phoneNumber": phoneNumber, "otp": inOtp }),
      })
      if (resp.status === 200) {
        let data = await resp.json()
        console.log(data)
        navigate(`/session/${data.id}`)
      } else {
        setTryAgain(true)
      }
    } catch (error) {
      setError(error)
    }
  }

  return (
    <Box className={pageStyles.popBox} sx={{ top: "57%" }}>
      <Container className={pageStyles.popBoxInside}>
        <Cancel onClick={handleAuthPop} className={pageStyles.cancel} />
        <Typography variant="h3" className={pageStyles.popBoxH3}>
          {`An OTP was sent to ${phoneNumber}`}
        </Typography>
        <hr />
        <List className={pageStyles.ul}>
          <>
            {tryagain === false ?
              (
                <ListItem className={pageStyles.li}>
                  <Typography variant="h4"> Please enter it below to comfirn your identity </Typography>
                </ListItem>) : (<ListItem className={pageStyles.li}>
                  <Typography variant="error"> {`The OTP you entered does not match the one sent to ${phoneNumber}`} </Typography>
                </ListItem>
              )}
          </>
          <ListItem className={pageStyles.li}>
            <OTPInput onOtpSubmit={handleAuth} />
          </ListItem>
        </List>
      </Container>
    </Box>
  );
};

export default OccupiedAuthBox;
