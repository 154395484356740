import React from "react";
import {
    Box,
    Container,
    Typography,
    List,
    ListItem,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { PageStyles } from "../assets/styles/page.js";


const PopUpError = ({ error, setError }) => {
    const pageStyles = PageStyles();
    return (
        <div>
            <Box className={pageStyles.popBox}>
                <Container className={pageStyles.popBoxInside}>
                    <Cancel
                        onClick={() => setError(null)}
                        className={pageStyles.cancel}
                    />
                    <Typography variant="h3" className={pageStyles.popBoxH3}>
                        ERROR
                    </Typography>
                    <hr />
                    <List className={pageStyles.ul}>
                        <ListItem className={pageStyles.li}>{error}</ListItem>
                    </List>
                </Container>
            </Box>
        </div>
    );
}

export default PopUpError