import React, { useState } from "react";
import { Box, Container, Typography, List, ListItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PageStyles } from "../assets/styles/page.js";

const AuthInfoBox = ({ api_host, paymentDetails, handleAuthInfo }) => {
  const pageStyles = PageStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [sess_created, setSessCreated] = useState(true);
  const handlePurchase = async () => {
    console.log("Handling purchase");
    setLoading(true);
    try {
      const resp = await fetch(`${api_host}/payments`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paymentDetails),
      })
      if (!resp.ok) {
        setLoading(false);
        setSessCreated(false);
        return
      }
      const data = await resp.json()
      return navigate(`/session/${data.id}`);
    } catch (error) {
      console.log("Error initaiting payment", error);
      setLoading(false);
      setSessCreated(false);
    }
  };

  const LoadingScreen = () => {
    return (
      <Typography variant="h4">
        Generating your session...PLEASE WAIT
      </Typography>
    )
  }

  const tryAgain = () => {
    return (
      <Typography variant="h4">
        Unable to generate a session for you please reload and try again
      </Typography>
    )
  }

  const AuthPopBox = () => {
    return (
      <>
        <Typography
          variant="h3"
          className={pageStyles.popBoxH3}
          sx={{ width: "120%", marginLeft: "-10%" }}
        >
          You are about to purchase
        </Typography>
        <List className={pageStyles.ul}>
          <ListItem className={pageStyles.licomfirm}>
            Price: {paymentDetails.amountPaid} Rwf
          </ListItem>
          <ListItem className={pageStyles.licomfirm}>
            Units: {Math.round(paymentDetails.amountPaid / paymentDetails.unitPrice * 100) / 100} Kwh
          </ListItem>
        </List>
        <Container className={pageStyles.popBoxfooter}>
          <Typography
            variant="h3"
            className={pageStyles.popBoxfooter1}
            onClick={handlePurchase}
          >
            OK
          </Typography>
          <Typography
            variant="h3"
            className={pageStyles.popBoxfooter2}
            onClick={handleAuthInfo}
          >
            Cancel
          </Typography>
        </Container>
      </>
    )
  }

  return (
    <Box className={pageStyles.popBox}>
      <Container className={pageStyles.popBoxInside}>
        {(loading) ? LoadingScreen() : (sess_created) ? AuthPopBox() : tryAgain()}
      </Container>
    </Box>
  );
};

export default AuthInfoBox;
