import { makeStyles } from "@mui/styles";

export const DashStyles = makeStyles((theme) => ({
  chargerContainer: {
    height: "145px",
    width: "70%",
    marginLeft: "15%",
    textAlign: "center",
    alignItems: "center",
  },
  otpBox: {
    maxHeight: "15px",
    maxWidth: "15px",
    margin: "2px",
    backgroundColor: theme.palette.whitecolors.input_light,
    borderColor: theme.palette.whitecolors.input_light,
    "&:hover": {
      border: "2px solid green"
    },

  },
  chargerBox: {
    position: "relative",
    height: "130px",
    width: "130px",
    overflow: "hidden",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "5px",
  },
  charger: {
    borderRadius: "50%",
    height: "130px",
    width: "130px",
    overflow: "hidden",
    backgroundColor: theme.palette.whitecolors.input_light,
  },
  chargerImg: {
    width: "100%",
    height: "100%",
  },
  chargerIcon: {
    fontSize: "23px",
    color: "rgba(8, 8, 8, 0.815)",
    position: "absolute",
    zIndex: "1",
    top: "10px",
    right: "7px",
    "&:hover": {
      color: theme.palette.primary.dark,
      cursor: "pointer",
    },
  },
  chargerList: {
    overflow: "hidden",
    listStyleType: "none",
    marginTop: "1px",
  },
  hr: { width: "60%", marginTop: "1px" },
  chargerListItem: {
    height: "20px",
    fontFamily: theme.typography.fontFamily,
    marginLeft: "-37px",
  },
  ul: {
    listStyleType: "none",
    padding: "0px",
    textAlign: "center",
    alignItems: "center",
    marginTop: "-5px",
    fontFamily: theme.typography.fontFamily,
  },
  li: {
    marginTop: "-7px",
    textAlign: "center",
    padding: "0px",
  },
  examples: {
    backgroundColor: theme.palette.whitecolors.input_light,
    maxWidth: "100%",
    height: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "4px",
    marginTop: "10px",
    alignItems: "center",
    paddingTop: "7px",
    zIndex: "1",
  },
  liLast: {
    paddingBottom: "10px",
  },

  flip: {
    animation: `$flipAnimation 1s`,
  },

  // Keyframes for the flip animation
  "@keyframes flipAnimation": {
    "0%": {
      transform: "rotateY(0deg)",
    },
    "50%": {
      transform: "rotateY(90deg)",
    },
    "100%": {
      transform: "rotateY(0deg)",
    },
  },
}));
