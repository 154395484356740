import { Outlet } from "react-router-dom";
import { Card, Box } from "@mui/material";
import { AppStyles } from "../assets/styles/AppStyles.js";
import Header from "../component/Header.js";
import Footer from "../component/footer.js";

const Layout = () => {
  const styles = AppStyles();
  return (
    <Box className={styles.app}>
      <Header />
      <Card className={styles.container}>
        <Outlet />
      </Card>
      <Footer />
    </Box>
  );
};

export default Layout;
