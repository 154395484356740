import { Container, Typography, Grid } from "@mui/material";
import AdbIcon from "@mui/icons-material/Adb";
import { StatusStyles } from "../assets/styles/StatusStyles.js";

const ErrorPaying = () => {
  const styles = StatusStyles();
  return (
    <Container>
      <AdbIcon
        className={styles.Errorbugs}
        sx={{ height: "55%", width: "25%", marginTop: "2%" }}
      />
      <Typography variant="h2" sx={{ color: "red" }}>
        ERROR
      </Typography>
      <Grid container justifyContent="center">
        <Typography variant="h4" sx={{ color: "green" }}>
          SCAN AGAIN
        </Typography>
      </Grid>
    </Container>
  );
};

export default ErrorPaying;
