import React, { useEffect, useState } from "react";
import {
  FormControl,
  Box,
  Container,
  Input,
  Typography,
} from "@mui/material";
import { DashStyles } from "../assets/styles/DashStyles.js";
import { PageStyles } from "../assets/styles/page.js";
import AuthInfoBox from "./AuthBoxInfo.js";
import ChargerHeader from "./ChargerHeader.js";
import PopUpError from "./PopUpError.js";

const Dashboard = ({ api_host, charger, rwandan_nums }) => {
  const styles = DashStyles();
  const pageStyles = PageStyles();
  const [authPop, setAuthPop] = useState(null);
  const [error, setError] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState({
    amountPaid: "",
    phoneNumber: "",
    charger: charger.id,
    units: 0,
    minAmount: 50,
    maAmount: 9999999999,
    unitPrice: 450
  });
  const [cars, setCars] = useState([]);
  const value_examples = { example_price: 2000, units: 10, max_amount: 100000 };
  const [credit, setCredit] = useState(value_examples.example_price);
  const humanizeDuration = require("humanize-duration");

  function calculateExamples(data) {
    const updatedCars = data.map(car => {
      const power = (Math.min(car.ac_phases, charger.specs.Phase) * 230 * Math.min(car.ac_current, charger.specs.Current)) / 1000;
      car.unitsToBeCharged = credit / paymentDetails.unitPrice;
      car.percentageToBeCharged = car.unitsToBeCharged / car.battery_capacity * 100;
      car.timeToCharge = (car.unitsToBeCharged / power) * 3600000; // converting minutes to milliseconds for humanise function
      return car;
    });
    setCars(updatedCars);
  }

  useEffect(() => {
    fetch(`${api_host}/Examples`)
      .then(response => response.json())
      .then(data => calculateExamples(data))
      .catch(error => console.error(error));
  }, [])

  useEffect(() => {
    calculateExamples(cars)
  }, [credit]);

  useEffect(() => {
    if (
      paymentDetails.amountPaid >= paymentDetails.minAmount &&
      paymentDetails.amountPaid % 1 === 0
    ) {
      setCredit(paymentDetails.amountPaid);
    } else if (
      paymentDetails.amountPaid < paymentDetails.minAmount ||
      paymentDetails.amountPaid % 1 !== 0
    ) {
      setCredit(value_examples.example_price);
    }

  }, [
    paymentDetails.amountPaid,
    setCredit,
    paymentDetails.minAmount,
    value_examples.example_price,
    value_examples.max_amount,
  ]);

  const submitForm = (e) => {
    e.preventDefault();
    if (paymentDetails.amountPaid === "" || paymentDetails.phoneNumber === "") {
      setError("Enter the amount and your phone number ");
    } else if (
      paymentDetails.amountPaid < 0 ||
      paymentDetails.amountPaid === "" ||
      paymentDetails.amountPaid % 1 !== 0 ||
      paymentDetails.amountPaid > paymentDetails.maAmount
    ) {
      setError("Enter a correct amount ");
    } else if (paymentDetails.amountPaid < paymentDetails.minAmount) {
      setError(
        `Minimum amount to purchase with is ${paymentDetails.minAmount} Rwf`);
    } else if (
      !/^\d+$/.test(paymentDetails.phoneNumber) ||
      paymentDetails.phoneNumber < 0 ||
      paymentDetails.phoneNumber % 1 !== 0 ||
      paymentDetails.phoneNumber.length !== 10 ||
      !rwandan_nums.some((prefix) =>
        paymentDetails.phoneNumber.startsWith(prefix),
      )
    ) {
      setError("Enter a correct phone number </n ie: 0780000000");
    } else if (
      paymentDetails.phoneNumber.startsWith("072") ||
      paymentDetails.phoneNumber.startsWith("073")
    ) {
      setError(`Only MTN numbers are supported`);
    } else {
      handleAuthInfo();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentDetails((prevpaymentDetails) => ({
      ...prevpaymentDetails,
      [name]: value,
    }));
    if (name === "amountPaid") {
      setPaymentDetails((prevpaymentDetails) => ({
        ...prevpaymentDetails,
        [name]: parseInt(value),
      }));
    }
  };

  const handleAuthInfo = () => {
    if (authPop !== "on") {
      setAuthPop("on");
    } else {
      setAuthPop(null);
    }
  };

  return (
    <Container className={pageStyles.the_page}>
      <ChargerHeader charger={charger} />
      {error && (
        <PopUpError error={error} setError={setError} />
      )}
      {authPop === "on" && (
        <AuthInfoBox
          api_host={api_host}
          paymentDetails={paymentDetails}
          handleAuthInfo={handleAuthInfo}
        />
      )}
      <Box className={pageStyles.formContainer}>
        <FormControl className={pageStyles.form}>
          <Typography variant="h4">Enter your details here</Typography>
          <Input
            placeholder="Amount"
            type="number"
            className={pageStyles.formInput}
            value={paymentDetails.amountPaid}
            name="amountPaid"
            onChange={handleChange}
          />
          <br />
        </FormControl>
        <FormControl className={pageStyles.form}>
          <Input
            placeholder="Phone number"
            type="text"
            className={pageStyles.formInput2}
            value={paymentDetails.phoneNumber}
            name="phoneNumber"
            onChange={handleChange}
          />
          <br />
          <button
            className={pageStyles.formButton}
            type="submit"
            onClick={submitForm}
          >
            Go
          </button>
        </FormControl>
      </Box>
      <Box className={styles.examples}>
        <Typography variant="h4">Price {paymentDetails.unitPrice} Rwf per 1 kWh </Typography>
        <Typography variant="h4">Examples for {credit} Rwf = Units: {Math.round(credit / paymentDetails.unitPrice * 100) / 100} kWh </Typography>
        <table className={pageStyles.table}>
          <tr>
            <th>Brand</th>
            <th>Model</th>
            <th>Trim</th>
            <th>Battery size</th>
            <th>Estimated Time to charge</th>
            <th>Percentage to charge</th>
          </tr>
          {cars.length !== 0 &&
            cars.map((car) => (
              <tr>
                <td>{car.brand}</td>
                <td>{car.model}</td>
                <td>{car.trim_level}</td>
                <td>{car.battery_capacity} kWh</td>
                <td>{humanizeDuration(Math.round(car.timeToCharge), { units: ["h", "m"], round: true })}</td>
                <td>{parseFloat(car.percentageToBeCharged).toFixed()} %</td>
              </tr>
            ))}
        </table>
      </Box>
    </Container>
  );
};

export default Dashboard;
