import React, { useEffect, useState } from "react";
import { PageStyles } from "../assets/styles/page.js";
import { useNavigate } from "react-router-dom";
import { FormControl, Box, Typography } from "@mui/material";
import PopUpError from "../component/PopUpError.js";

const Home = ({ api_host, stationId }) => {
  const navigate = useNavigate();
  const pageStyles = PageStyles();
  const [chargerId, setchargerid] = useState();
  const [chargers, setchargers] = useState([]);
  const [error, setError] = useState(null);
  const handleChange = (event) => {
    setchargerid(event.target.value);
  };

  useEffect(() => {
    fetch(`${api_host}/Stations/${stationId}/chargers`)
      .then(response => response.json())
      .then(data => setchargers(data))
      .catch(error => console.error(error));
  }, [])

  async function submitCharger(event) {
    event.preventDefault();
    let id = chargerId
    if (!id) {
      let firstcharger = chargers[0];
      id = firstcharger.id
    }
    const resp = await fetch(`${api_host}/Stations/${stationId}/chargers/${id}`);
    if (!resp.ok) {
      return setError("Unable to check charger status please try again")
    }
    return navigate(`/charger/${id}`);
  }

  return (
    <>
      {error && (
        <PopUpError error={error} setError={setError} />
      )}
      <Box className={pageStyles.formContainer}>
        <FormControl className={pageStyles.form}>
          <div>
            {chargers.length !== undefined ? (
              <>
                <Typography variant="h4">Select a charger by Id</Typography>
                <select id="charger" value={chargerId} onChange={handleChange}>
                  {
                    chargers.map((ch, key) => (
                      <option key={key} value={ch.id} >{ch.id}</option>
                    ))
                  }
                </select>
                <div style={{ marginTop: "2vh" }}>
                  <button
                    className={pageStyles.formButton}
                    type="submit"
                    onClick={submitCharger}
                  >
                    Go
                  </button>
                </div>
              </>) : <Typography variant="error">Unable to load available chargers</Typography>
            }
          </div>
        </FormControl >
      </Box>
    </>
  );


};
export default Home;
