import { List, ListItem, Container } from "@mui/material";
import { StatusStyles } from "../assets/styles/StatusStyles.js";
import { PageStyles } from "../assets/styles/page.js";

const SessionStatus = ({
  chargingDetails,
  sess_Status,
  statusStages,
  value_0,
}) => {
  const styles = StatusStyles();
  const pageStyles = PageStyles();
  const humanizeDuration = require("humanize-duration");

  return (
    <Container className={styles.updates}>
      <List className={pageStyles.ul}>
        <ListItem className={pageStyles.li}>
          Speed: {chargingDetails.speed && chargingDetails.speed.toFixed(2)} kW
        </ListItem>
        <ListItem className={pageStyles.li}>
          Voltage:{" "}
          {chargingDetails.voltage && chargingDetails.voltage.toFixed(2)} V
        </ListItem>
        <ListItem className={pageStyles.li}>
          Current:{" "}
          {chargingDetails.current && chargingDetails.current.toFixed(2)} A
        </ListItem>
        <ListItem className={pageStyles.li}>
          Total charged:
          {chargingDetails.unitsCharged
            ? chargingDetails.unitsCharged.toFixed(
              chargingDetails.unitsCharged > value_0 ? 2 : 3,
            )
            : null}{" "}
          kWh
        </ListItem>
        {sess_Status === statusStages.Charging && (
          <>
            <ListItem className={pageStyles.li}>
              Remaining charge:
              {chargingDetails.unitsLeft
                ? chargingDetails.unitsLeft.toFixed(
                  chargingDetails.unitsLeft > value_0 ? 2 : 3,
                )
                : null}{" "}
              kWh
            </ListItem>

            <ListItem className={pageStyles.li}>
              Been charging for:
              {humanizeDuration(Math.round(chargingDetails.chargingTime * 1000), { units: ["h", "m"], round: true })}
            </ListItem>
          </>
        )}
        {(sess_Status === statusStages.DoneCharging ||
          sess_Status === statusStages.Interrupted) && (
            <ListItem className={pageStyles.li}>
              Remaining Amount:{" "}
              {chargingDetails.amountLeft &&
                chargingDetails.amountLeft.toFixed(0)}{" "}
              Rwf
            </ListItem>
          )}
      </List>
    </Container>
  );
}

export default SessionStatus;
