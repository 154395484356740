import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import { keyframes } from "@mui/styled-engine";
import { CardMedia } from "@mui/material";

export const StatusStyles = makeStyles((theme) => ({
  Errorbugs: {
    alignment: "center",
    padding: "20px",
  },
  updates: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  statusTopIcon: {
    paddingLeft: "45px",
    paddingRight: "45px",
    maxWidth: "230px",
    maxHeight: "230px",
    marginTop: "50px",
    alignItems: "center",
    display: "flex",
  },
  imgcontainer: {
    position: "relative",
    marginBottom: "3px",
    marginTop: "5px",
    alignSelf: "center",
  },
  chargerImg: {
    objectFit: "cover",
    borderRadius: "50%",

  },
  headings: { marginTop: "50px" },
  h2: { marginTop: "10px" },
  h3: { marginTop: "10px" },
  h3holder: {
    height: "18px",
    width: "80%",
    marginLeft: "10%",
    marginTop: "10px",
  },
  h3_status: {
    float: "right",
    width: "55%",
    textAlign: "left",
    height: "100%",
  },
  IconHolder: {
    marginLeft: "25%",
    width: "20%",
    height: "100%",
    position: "relative",
  },
  ChargerIcon: {
    rotate: "90deg",
    position: "absolute",
    top: "-20%",
    right: "20%",
    zIndex: "0",
  },
  iconBackground: {
    rotate: "90deg",
    position: "absolute",
    top: "5%",
    right: "36.5%",
    Zindex: "1",
  },
}));

const rotatingAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const RotatingImage = styled(CardMedia)`
  height: 150px;
  width: 150px;
  border: 1px dotted #7ae045;
  border-radius: 50%;
  animation: ${rotatingAnimation} 3s linear infinite;
`;

// 3D BALL
/*
const rotatingAnimation = keyframes`
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: rotateX(180deg) rotateY(180deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
`;

export const RotatingImage = styled(CardMedia)`
  height: 100%;
  width: 100%;
  border: 1px dotted #7ae045;
  border-radius: 50%;
  animation: ${rotatingAnimation} 10s linear infinite;
  transform-style: preserve-3d;
`;
*/
/*
//3D ROTATING BALL
const rotatingAnimation = keyframes`
  from {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  to {
    transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
  }
`;

export const RotatingImage = styled(CardMedia)`
  height: 100%;
  width: 100%;
  border: 1px dotted #7ae045;
  border-radius: 50%;
  animation: ${rotatingAnimation} 10s linear infinite;
  transform-style: preserve-3d;
  transform: translateZ(-50px);
`;
*/
