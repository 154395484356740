import React, { useEffect, useState } from "react";
import SessionHeader from "../component/SessionHeader.js";
import HeroImage from "../component/HeroImage";
import { useParams } from "react-router-dom";
import ErrorPaying from "../component/errorPaying";
import { PageStyles } from "../assets/styles/page.js";
import { status_imgs } from "../component/statusimgs.js";
import { Box, Container, Typography } from "@mui/material";
import meshLog from "../assets/images/meshLog4.jpg";
import { primary_main, secondary_main, red } from "../theme.js";
import { StatusStyles } from "../assets/styles/StatusStyles.js";
import BatteryChargingFullRoundedIcon from "@mui/icons-material/BatteryChargingFullRounded";
import BatteryCharging50RoundedIcon from "@mui/icons-material/BatteryCharging50Rounded";
import BoltRoundedIcon from "@mui/icons-material/BoltRounded";
import SessionStatus from "../component/SessionStatus.js";
import { useNavigate } from "react-router-dom";
import PopUpError from "../component/PopUpError.js";

const Session = ({ api_host }) => {
  const styles = StatusStyles();
  const pageStyles = PageStyles();
  const [iconColor, setIconColor] = useState();
  const [icon, setIcon] = useState();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  let { sessionId } = useParams();

  let value_0 = 0.01;

  const [sess_Status, setsess_Status] = useState(null);
  const statusStages = {
    Interrupted: "NOT CHARGING",
    DoneCharging: "DONE CHARGING",
    Charging: "CHARGING",
    Err: "ERROR",
    PaymentDone: "PAYMENT DONE",
    Loading: "LOADING",
  };

  const apiResponses = {
    allGood: 200,
    failedSession: 501,
    failedAction: 503,
  };

  const [chargingDetails, setChargingDetails] = useState()

  async function checkSession() {
    try {
      console.log("fetching data");
      const response = await fetch(
        `${api_host}/Sessions/Active/${sessionId}`,
      ).then((response) => response.json());
      setChargingDetails({
        current: response.status.current, voltage: response.status.voltage,
        speed: response.status.speed, unitsCharged: response.status.unitsCharged,
        unitsLeft: response.status.unitsLeft, amountLeft: response.status.amountLeft,
        chargingTime: response.status.chargingTime, batteryHealth: response.status.batteryHealth,
        status: response.status.status,
      })
      setsess_Status(response.status.status);
    } catch (error) {
      console.log("Error fetching Session:", error);
      navigate("/notfound");
    }
  }

  const stopCharging = () => {
    setsess_Status(statusStages.Loading)
    fetch(`${api_host}/stopcharging/${sessionId}`).then((res) => {
      switch (res.status) {
        case (apiResponses.allGood):
          return checkSession();

        case (apiResponses.failedAction):
          checkSession()
          return setError("Failed to resume charging please check if the charger is not in use and again")

        default:
          checkSession()
          return setError("Failed to reach the server please try again")
      }
    })
  };

  const startCharging = () => {
    setsess_Status(statusStages.Loading)
    fetch(`${api_host}/startcharging/${sessionId}`).then((res) => {
      switch (res.status) {
        case (apiResponses.allGood):
          return res.json();

        case (apiResponses.failedSession):
          checkSession()
          return setError("Failed to generate a new session please try again")

        case (apiResponses.failedAction):
          checkSession()
          return setError("Failed to resume charging please check if the charger is not in use and again")

        default:
          checkSession()
          return setError("Failed to reach the server please try again")
      }
    }).then(data => { navigate(`/session/${data.id}`); })
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (sess_Status !== statusStages.DoneCharging || sess_Status !== statusStages.Interrupted) {
        checkSession()
      }
    }, 10000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    switch (sess_Status) {
      case (statusStages.Interrupted):
        setIconColor(red);
        setIcon(<BatteryCharging50RoundedIcon />);
        break;
      case (statusStages.DoneCharging):
        setIconColor(primary_main);
        setIcon(<BatteryChargingFullRoundedIcon />);
        break;
      case (statusStages.Charging):
        setIconColor(secondary_main);
        setIcon(<BatteryCharging50RoundedIcon />);
        break;
      default:
        setIconColor(primary_main);
        setIcon(<BatteryChargingFullRoundedIcon />);
    }
  }, [sess_Status])

  // console.log(sess_Status);

  return (
    <Container className={pageStyles.the_page}>
      {(sess_Status === null) ? (
        <>
          {setsess_Status(statusStages.Loading)}
          {checkSession()}
        </>
      ) : (
        <>
          {
            {
              [statusStages.Loading]: (
                <SessionHeader
                  source={meshLog}
                  rotate={true}
                  header_one="PROCESSING ... "
                  header_two="PLEASE WAIT"
                />
              ),
              [statusStages.PaymentDone]: (
                <SessionHeader
                  source={status_imgs.plugin}
                  rotate={false}
                  header_one="PAYMENT COMPLETED"
                  header_two="PLUG IN THE CHARGER"
                />
              ),
              [statusStages.Err]: <ErrorPaying />,
              [statusStages.Charging]: (
                <HeroImage source={status_imgs.charging} rotate={false} click_function={stopCharging} />
              ),
              [statusStages.Interrupted]: (
                <HeroImage source={status_imgs.interrupted} rotate={false} click_function={startCharging} />
              ),
              [statusStages.DoneCharging]: (
                <HeroImage source={status_imgs.plugin} rotate={false} />
              ),
            }[sess_Status]
          }
        </>
      )}
      {(sess_Status === statusStages.Charging ||
        sess_Status === statusStages.Interrupted ||
        sess_Status === statusStages.DoneCharging) && (
          <div>
            <Box className={styles.headings}>
              {error && (
                <PopUpError error={error} setError={setError} />
              )}
              <Typography variant="h2" sx={{ color: iconColor }}>
                {sess_Status}
              </Typography>
              <Box className={styles.h3holder}>
                <Typography variant="h3" className={styles.h3_status}>
                  STATUS
                </Typography>
                <Box className={styles.IconHolder}>
                  {icon &&
                    React.cloneElement(icon, {
                      className: styles.ChargerIcon,
                      sx: { fontSize: 25, color: iconColor },
                    })}
                  <BoltRoundedIcon
                    className={styles.iconBackground}
                    sx={{ fontSize: 15 }}
                  />
                </Box>
              </Box>
            </Box>
            <SessionStatus chargingDetails={chargingDetails} sess_Status={sess_Status} statusStages={statusStages} value_0={value_0} />
          </div>
        )}
    </Container>
  );
};

export default Session;
