import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Charger from "./pages/Charger.js";
import Session from "./pages/Session.js";
import Layout from "./pages/layout.js";
import Home from "./pages/Home.js";
import { Typography } from "@mui/material";

function App() {
  const api_host = "http://ev.meshpower.co.rw:5000";
  const stationId = 1;
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={<Home api_host={api_host} stationId={stationId} />}
          />
          <Route
            path="charger/:chargerId"
            element={<Charger api_host={api_host} stationId={stationId} />}
          />
          <Route
            path="session/:sessionId"
            element={<Session api_host={api_host} stationId={stationId} />}
          />
          <Route
            path="*"
            element={<Typography>404</Typography>}
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
