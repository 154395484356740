import { Box, CardMedia, Container } from "@mui/material";
import { StatusStyles, RotatingImage } from "../assets/styles/StatusStyles.js";
import { motion } from "framer-motion"

const HeroImage = ({ source, rotate, click_function }) => {
  const styles = StatusStyles();
  const NonStaticImage = () => {
    return <RotatingImage component="img" src={source} alt="" />;
  };
  function StaticImage() {
    return (
      <motion.div whileTap={{ scale: 1.5 }}>
        <CardMedia
          component="img"
          src={source}
          alt="image"
          className={styles.chargerImg}
          onClick={click_function}
        />
      </motion.div>
    );
  };
  return (<div style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
    <Box className={styles.statusTopIcon}>
      <Container className={styles.imgcontainer}>
        {rotate === true ? NonStaticImage() : StaticImage()}
      </Container>
    </Box>
  </div>
  );
};

export default HeroImage;
