import React from "react";
import { Link } from 'react-router-dom';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import logo from "../assets/images/logo.png";
import { CardMedia } from "@mui/material";
import { HeaderStyles } from "../assets/styles/HeaderStyles";

function Header() {
  const styles = HeaderStyles();
  return (
    <AppBar position="static" className={styles.header}>
      <Toolbar className={`${styles.toolbar} ${styles.center}`}>
        <Link to={'/'}>
          <CardMedia component="img" src={logo} alt="mesh logo" style={{ maxHeight: "80px" }} />
        </Link>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
