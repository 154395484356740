import React, { useEffect, useState } from "react";
import Dashboard from "../component/Dashboard";
import Occupied from "../component/Occupied";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";

const Charger = ({ api_host, stationId }) => {
  const rwandan_nums = ["072", "078", "073", "079"];
  const [charger, setCharger] = useState(null);
  let { chargerId } = useParams();

  useEffect(() => {
    async function GetChargerData() {
      const res = await fetch(
        `${api_host}/Stations/${stationId}/chargers/${chargerId}`,
      );
      if (!res.ok) {
        return
      }
      const data = await res.json();
      setCharger(data);
    }
    GetChargerData();
  }, []);

  function displayDashboard() {
    return <Dashboard api_host={api_host} charger={charger} rwandan_nums={rwandan_nums} />;
  }

  function displayOccupied() {
    return <Occupied api_host={api_host} rwandan_nums={rwandan_nums} charger={charger} />;
  }

  return (
    <>
      {charger === null
        ? (<Typography variant="error">Enable to get charger availability, Please try again</Typography>)
        : charger["active"] === false
          ? displayDashboard()
          : displayOccupied()}
    </>
  );
};

export default Charger;
