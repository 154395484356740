import { makeStyles } from "@mui/styles";

export const FooterStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    border: "1px solid #43ab01",
    height: "55px",
    padding: "0",
    margin: "0",
    width: "100%",
    justifyContent: "center", // Center the items horizontally
    alignItems: "center", // Center the items vertically
  },
  toolbar: {
    display: "flex",
    justifyContent: "center", // Center the items horizontally
    alignItems: "center", // Center the items vertically
    width: "60%",
  },
}));
