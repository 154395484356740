import { Box, Typography, CardMedia, Container } from "@mui/material";
import { StatusStyles } from "../assets/styles/StatusStyles.js";
import HeroImage from "./HeroImage.js";
const SessionHeader = ({ source, rotate, header_one, header_two }) => {
  const styles = StatusStyles();
  // console.log(header_one);

  return (
    <div>
      <HeroImage source={source} rotate={rotate} />
      <Box className={styles.headings}>
        <Box className={styles.h2}>
          <Typography variant="h2">{header_one}</Typography>
        </Box>
        <Box className={styles.h3}>
          <Typography variant="h3" className={styles.h3}>
            {header_two}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default SessionHeader;
